







































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import NavigationLink from '../components/Common/NavigationLink.vue';

@Component({
  components: {
    NavigationLink,
  },
})
export default class LandlordServices extends Mixins(View) {}
